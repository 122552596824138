import authedFetch from "../util/authedFetch";
import { ElmPorts } from "../Types/ElmPorts";
import * as Helpers from "../Learnosity/helpers";
import EvaluateComponent from "./evaluateComponent";
import EvaluatePreviewComponent from "./evaluatePreviewComponent";
import { FetchFunctionType, TTS } from "./commonTypes";

type Ports = ElmPorts<{
  subscribe: {
    componentShow: string;
    componentRemove: string;
  };
  send: {
    componentRemoved: null;
    componentEvent: object;
  };
}>;

type PlatformData = {
  serverBaseUrl: string;
};

export default function setupComponentPorts(
  ports: Ports,
  platformData: PlatformData,
  tts: any
): void {
  ports.componentShow.subscribe((componentDataAsJson: string): void => {
    const data = JSON.parse(componentDataAsJson);
    console.log("componentShow command");
    console.log(data);
    if (data.componentType == "evaluate") {
      new EvaluateComponent(fetchFunction, data.studentActivityId, onEvent, tts, data.audioEnabled);
    } else if (data.componentType == "evaluate_preview") {
      new EvaluatePreviewComponent(fetchFunction, tts, data.audioEnabled);
    }
  });

  ports.componentRemove.subscribe((componentDataAsJson: string): void => {
    const data = JSON.parse(componentDataAsJson);
    console.log("componentRemove command");
    console.log(data);
    if (data.componentType == "evaluate") {
      EvaluateComponent.returnInstance()?.removeInstance();
    }
    window.requestAnimationFrame(() => {
      ports.componentRemoved.send(null);
    });
  });

  const fetchFunction = async (
    input: string,
    init: Record<string, string> | undefined = {}
  ): Promise<Response> => {
    const baseUrl = platformData.serverBaseUrl;
    return await authedFetch(new URL(input, baseUrl).toString(), init);
  };

  const onEvent = (toSend: object): void => {
    ports.componentEvent.send(toSend);
  };
}
